import { Button, Stack } from "@mui/material"

const VARIANT = "contained"

const SizesButton = ({sizesVisible, setSizesVisible}) => {
    return (
        <Button onClick={() => setSizesVisible(!sizesVisible)} variant={VARIANT}>
            {sizesVisible ? "скрыть размеры" : "показать размеры"}
        </Button>
    )
}

export {SizesButton}

const AnimationButton = ({animation, setAnimation}) => {
    return (
        <Button onClick={() => setAnimation(!animation)} variant={VARIANT}>
            {animation ? "остановить анимацию" : "запустить анимацию"}
        </Button> 
    )
}

export {AnimationButton}

const InfoButton = ({infoVisible, setInfoVisible}) => {

    return (
        <Button onClick={() => setInfoVisible(!infoVisible)} variant={VARIANT}>
            {infoVisible ? "скрыть детали" : "показать детали"}
        </Button>
    )
}

export {InfoButton}

const ControlButtons = (props) => {

    const {
        sizesVisible, setSizesVisible,
        infoVisible, setInfoVisible,
        animation, setAnimation
    } = props

    return (
        <Stack 
            direction="column"
            sx={{m: 1}}
            spacing={1}
        >
            <SizesButton 
                sizesVisible={sizesVisible} 
                setSizesVisible={setSizesVisible}
            />
            <InfoButton 
                infoVisible={infoVisible}
                setInfoVisible={setInfoVisible}
            />
            <AnimationButton 
                animation={animation}
                setAnimation={setAnimation}
            />
        </Stack>
    )
}

export {ControlButtons}
