import { ThemeProvider } from '@emotion/react';
import './App.css';
import Viewer from './components/Viewer';
import {mainTheme} from './assets/theme'

function App() {
  
  return (
    <ThemeProvider theme={mainTheme}>
      <Viewer />
    </ThemeProvider>
  );
}

export default App;
