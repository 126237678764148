import {useCallback, useEffect, useState, useRef} from 'react'
import Astronaut from '../models/Astronaut.glb'
import Brain from '../models/Brain.glb'
import sphere_bot from '../models/sphere_bot.glb'
import stanok from '../models/stanok.glb'
import printer from '../models/printer.glb'
import background from '../models/i.webp'
import {Box, Button, Pagination, Stack, TextField, Typography} from '@mui/material'
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'
import { AnimationButton, ControlButtons, InfoButton, SizesButton } from './Buttons'
import { AnnotationsMask } from './Annotations'
import DescriptionBox from './DescriptionBox'


const drawLine = (svgLine, dotHotspot1, dotHotspot2, dimensionHotspot) => {
    if (dotHotspot1 && dotHotspot2) {
      svgLine.setAttribute('x1', dotHotspot1.canvasPosition.x);
      svgLine.setAttribute('y1', dotHotspot1.canvasPosition.y);
      svgLine.setAttribute('x2', dotHotspot2.canvasPosition.x);
      svgLine.setAttribute('y2', dotHotspot2.canvasPosition.y);

      if (dimensionHotspot && !dimensionHotspot.facingCamera) {
        svgLine.classList.add('hide');
      }
      else {
        svgLine.classList.remove('hide');
      }
    }
}

export default function Viewer() {
    const WindowWidth = useWindowWidth()


    const modelRef = useRef(null)

    const [sizesVisible, setSizesVisible] = useState(false)
    const [animation, setAnimation] = useState(true)
    const [infoVisible, setInfoVisible] = useState(false)
  
    const [page, setPage] = useState(1)

    const hanglePageChange = (event, value) => {
      setPage(value)
    }

    const renderSizes = useCallback(() => {
        const modelViewer = modelRef.current
        const dimElements = [...modelViewer.querySelectorAll('button'), modelViewer.querySelector('#dimLines')]

        const setVisibility = (visible) => {
            dimElements.forEach((element) => {
                visible 
                    ? element.classList.remove('hide')
                    : element.classList.add('hide')
                setSizesVisible(visible)
            })
        }

        const dimLines = modelViewer.querySelectorAll('line')
        const renderSVG = () => {
            drawLine(dimLines[0], modelViewer.queryHotspot('hotspot-dot+X-Y+Z'), modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Y'));
            drawLine(dimLines[1], modelViewer.queryHotspot('hotspot-dot+X-Y-Z'), modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dim+X-Z'));
            drawLine(dimLines[2], modelViewer.queryHotspot('hotspot-dot+X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X+Y-Z')); // always visible
            drawLine(dimLines[3], modelViewer.queryHotspot('hotspot-dot-X+Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dim-X-Z'));
            drawLine(dimLines[4], modelViewer.queryHotspot('hotspot-dot-X-Y-Z'), modelViewer.queryHotspot('hotspot-dot-X-Y+Z'), modelViewer.queryHotspot('hotspot-dim-X-Y'));
        }
        modelViewer.addEventListener('load', () => {
            const center = modelViewer.getBoundingBoxCenter();
            const size = modelViewer.getDimensions();
            const x2 = size.x / 2;
            const y2 = size.y / 2;
            const z2 = size.z / 2;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot+X-Y+Z',
              position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`
            });
        
            modelViewer.updateHotspot({
              name: 'hotspot-dim+X-Y',
              position: `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+X-Y"]').textContent =
                `${(size.z * 100).toFixed(0)} см`;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot+X-Y-Z',
              position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`
            });
        
            modelViewer.updateHotspot({
              name: 'hotspot-dim+X-Z',
              position: `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+X-Z"]').textContent =
                `${(size.y * 100).toFixed(0)} см`;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot+X+Y-Z',
              position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`
            });
        
            modelViewer.updateHotspot({
              name: 'hotspot-dim+Y-Z',
              position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim+Y-Z"]').textContent =
                `${(size.x * 100).toFixed(0)} см`;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot-X+Y-Z',
              position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`
            });
        
            modelViewer.updateHotspot({
              name: 'hotspot-dim-X-Z',
              position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim-X-Z"]').textContent =
                `${(size.y * 100).toFixed(0)} см`;
                
            modelViewer.updateHotspot({
              name: 'hotspot-dot-X-Y-Z',
              position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`
            });

            modelViewer.updateHotspot({
              name: 'hotspot-dim-X-Y',
              position: `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`
            });
            modelViewer.querySelector('button[slot="hotspot-dim-X-Y"]').textContent =
                `${(size.z * 100).toFixed(0)} см`;
        
            modelViewer.updateHotspot({
              name: 'hotspot-dot-X-Y+Z',
              position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`
            });
        
            renderSVG();
        
            modelViewer.addEventListener('camera-change', renderSVG)
    })}, [])

    useEffect(() => {
        renderSizes()
    }, [renderSizes])
    
    useEffect(() => {

        const modelViewer = modelRef.current

        const dimElements = [...modelViewer.querySelectorAll('button'), modelViewer.querySelector('#dimLines')]

        const setVisibility = (visible) => {
            dimElements.forEach((element) => {
                visible 
                    ? element.classList.remove('hide')
                    : element.classList.add('hide')
                setSizesVisible(visible)
            })
        }
        setVisibility(sizesVisible)

    }, [sizesVisible])

    useEffect(() => {
      const modelViewer = modelRef.current
      if (animation) {
        modelViewer.play()
      } else {
        modelViewer.pause()
      }
    }, [animation])
    
    return (
      <Box sx={{height: "100dvh", width: "100%", display: 'flex', position: "static",
        justifyContent: 'center',
        alignItems: 'center', bgcolor: "primary.white"}}
      >
        {WindowWidth < 640 
          ? <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          
            sx={{width: "100%", height: "100%"}}
          >
            <model-viewer
              id="main-panel"
              ref={modelRef}
              src={printer}
              camera-orbit="45deg 55deg 4m"
              style={{width: "100%", height: "100%"}} 
              autoplay
              ar camera-controls  ar-scale="fixed" 
              skybox-image={background}
             >
              <button slot="hotspot-dot+X-Y+Z" className="dot" data-position="1 -1 1" data-normal="1 0 0"></button>
              <button slot="hotspot-dim+X-Y" className="dim" data-position="1 -1 0" data-normal="1 0 0"></button>
              <button slot="hotspot-dot+X-Y-Z" className="dot" data-position="1 -1 -1" data-normal="1 0 0"></button>
              <button slot="hotspot-dim+X-Z" className="dim" data-position="1 0 -1" data-normal="1 0 0"></button>
              <button slot="hotspot-dot+X+Y-Z" className="dot" data-position="1 1 -1" data-normal="0 1 0"></button>
              <button slot="hotspot-dim+Y-Z" className="dim" data-position="0 -1 -1" data-normal="0 1 0"></button>
              <button slot="hotspot-dot-X+Y-Z" className="dot" data-position="-1 1 -1" data-normal="0 1 0"></button>
              <button slot="hotspot-dim-X-Z" className="dim" data-position="-1 0 -1" data-normal="-1 0 0"></button>
              <button slot="hotspot-dot-X-Y-Z" className="dot" data-position="-1 -1 -1" data-normal="-1 0 0"></button>
              <button slot="hotspot-dim-X-Y" className="dim" data-position="-1 -1 0" data-normal="-1 0 0"></button>
              <button slot="hotspot-dot-X-Y+Z" className="dot" data-position="-1 -1 1" data-normal="-1 0 0"></button>
              <AnnotationsMask infoVisible={infoVisible} />
              <svg id="dimLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="dimensionLineContainer">
                  <line className="dimensionLine"></line>
                  <line className="dimensionLine"></line>
                  <line className="dimensionLine"></line>
                  <line className="dimensionLine"></line>
                  <line className="dimensionLine"></line>
              </svg>
            </model-viewer>
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{height: "45%", width: "100%", bgcolor: "primary.white", position: "relative"}}
            >
              {page === 1 && <DescriptionBox />}
              {page === 2 && <ControlButtons 
                sizesVisible={sizesVisible} 
                setSizesVisible={setSizesVisible}
                infoVisible={infoVisible}
                setInfoVisible={setInfoVisible}
                animation={animation}
                setAnimation={setAnimation}
              />}
              
              <Stack sx={{position: 'sticky', bottom: 0, bgcolor: "white", alignItems: "center", justifyContent: "center"}}>
                <Pagination count={2} page={page} onChange={hanglePageChange}
                  sx={{py: 1}}
                />
              </Stack>
            </Stack>
          </Stack>
          : <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="space-between"
            sx={{width: "100%", height: "100%", bgcolor: "primary.white"}}
          >
            <model-viewer
              id="main-panel"
              ref={modelRef}
              src={printer}
              camera-orbit="45deg 55deg 4m"
              style={{width: "100%", height: "100%"}} 
              autoplay
              ar camera-controls  ar-scale="fixed" skybox-image={background}
            >
              <button slot="hotspot-dot+X-Y+Z" className="dot" data-position="1 -1 1" data-normal="1 0 0"></button>
              <button slot="hotspot-dim+X-Y" className="dim" data-position="1 -1 0" data-normal="1 0 0"></button>
              <button slot="hotspot-dot+X-Y-Z" className="dot" data-position="1 -1 -1" data-normal="1 0 0"></button>
              <button slot="hotspot-dim+X-Z" className="dim" data-position="1 0 -1" data-normal="1 0 0"></button>
              <button slot="hotspot-dot+X+Y-Z" className="dot" data-position="1 1 -1" data-normal="0 1 0"></button>
              <button slot="hotspot-dim+Y-Z" className="dim" data-position="0 -1 -1" data-normal="0 1 0"></button>
              <button slot="hotspot-dot-X+Y-Z" className="dot" data-position="-1 1 -1" data-normal="0 1 0"></button>
              <button slot="hotspot-dim-X-Z" className="dim" data-position="-1 0 -1" data-normal="-1 0 0"></button>
              <button slot="hotspot-dot-X-Y-Z" className="dot" data-position="-1 -1 -1" data-normal="-1 0 0"></button>
              <button slot="hotspot-dim-X-Y" className="dim" data-position="-1 -1 0" data-normal="-1 0 0"></button>
              <button slot="hotspot-dot-X-Y+Z" className="dot" data-position="-1 -1 1" data-normal="-1 0 0"></button>
              <AnnotationsMask infoVisible={infoVisible} />
              <svg id="dimLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="dimensionLineContainer">
                  <line className="dimensionLine"></line>
                  <line className="dimensionLine"></line>
                  <line className="dimensionLine"></line>
                  <line className="dimensionLine"></line>
                  <line className="dimensionLine"></line>
              </svg>
            </model-viewer>  
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{width: 420, height: "100%", overflow: "scroll"}}
            >
              <DescriptionBox />
              <ControlButtons 
                sizesVisible={sizesVisible} 
                setSizesVisible={setSizesVisible}
                infoVisible={infoVisible}
                setInfoVisible={setInfoVisible}
                animation={animation}
                setAnimation={setAnimation}
              />
            </Stack>
          </Stack>
        }
      </Box>
    )
}