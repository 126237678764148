import { Box, Typography } from "@mui/material";

const specs = [
    ["Объём камеры", "2 мdsa3"],
    ["Предельное давление", "10s-6 мбар"],
    ["Скорость откачки по ксенону", "99 л/с"],
    ["Суммарная скорость откачки по аоту", "99 л/с"],
    ["Место требует", "внимания"],
    ["Тут могла бы", "быть ваша реклама"],
    ["Мало лишнего текста", "2 м3"],
    
]

export default function DescriptionBox() {
    return (
        <Box sx={{position: "relative", overflow: "scroll"}}>
            <Typography variant='h6' sx={{ top: 0, position: "sticky", bgcolor: "primary.main", color: "white", textAlign: "center"}}>
                Общее описание
            </Typography>
            <Typography sx={{fontSize: 18, fontWeight: "bold", textAlign: "left", mx: 2, my: 1}}>
                3D-принтер VS3D X160 - компактный профессиональный принтер для повседневных задач
            </Typography>
            <Typography sx={{fontSize: 18, mx: 2}}>
                Модель X160 сочетает в себе самую высокую скорость печати на Российском рынке* 
                с непревзойдённой точностью, обеспечивает качественные результаты в короткий срок. 
            </Typography>
            <br/>
            <Typography sx={{fontSize: 18, mx: 2}}>
                Интегрируется с MES системйо LIner для создания автономной фермы 3D-печати.
            </Typography>
            <br/>
            <Typography sx={{fontSize: 18, mx: 2}}>
                3D-принтеры от компании VS3D, подходят для профессионального и 
                промышленного использования, дают возможность печатать сложными 
                композиными материалами, делая это с высокой точностью и качеством.
            </Typography>
            <br/>
            <Typography sx={{fontSize: 14, mx: 2}}>
                *Проверено в рамках исследования рынка по паспортных характеристикам и другим параметрам.
            </Typography>
            {/* {specs.map(spec => 
                <Box key={spec[0]} sx={{textAlign: "left", mx: 2,}}>
                    <Typography sx={{fontSize: 18}}>
                        {spec[0]}: {spec[1]}
                    </Typography>
                </Box>
            )} */}
        </Box>
    )
}