import { Box, Tooltip, Typography } from "@mui/material"

const annotations = [
    ["hotspot-1", "225 6 633 664 263 0.467 0.034 0.500", "Радиатор", "Радиатор охлаждающий холодную зону и зону термического разрыва. Необходим чтобы горячий пластик не поднимался выше холодной зоны и не создовал пробку"],
    ["hotspot-2", "225 0 2924 2928 2965 0.185 0.057 0.758", "Фидер", "Система подачи филамента"],
    ["hotspot-3", "55 1 72 71 73 0.052 0.607 0.342", "Кнопка питания", "Пункт подключения к сети и выключения станка"],
    ["hotspot-4", "227 0 480 481 579 0.693 0.134 0.174", "Балка", "Композитная основа, на которой крепится стальная рельса оси X, а так же ремни кинематики"],
    ["hotspot-5", "242 0 758 539 420 0.301 0.396 0.304", "Алюминиевый портал", "Основа кинематики 3д принтера. На портале установлены основные печатные элементы и система движения кинематики"],
    ["hotspot-6", "180 0 139 125 140 0.418 0.484 0.098", "Винт оси Z", "Приводит в движение горячий стол для послойного выращивания модели в моменте печати"],
    ["hotspot-7", "33 0 38 3 110 0.450 0.103 0.447", "Горячий стол", "Нагревающаяся пластина. Служит основой для печати моделей. Нагревается до 150 градусов"],
    ["hotspot-8", "225 20 1414 1415 1470 0.205 0.112 0.683", "Мотор фидера", "Мотор, который приводит в движение систему подачи филамента в зону нагревателя и в зону расплава"],
    ["hotspot-9", "225 2 619 580 620 0.059 0.015 0.926", "Носок", "Силиконовый кожух, защищающий нагревательный блок от расплава, предотвращает быстрое остывания нагревателя в момент активного обдува детали"],
    ["hotspot-10", "225 5 223 224 178 0.129 0.487 0.384", "Сопло", "Элемент печастной головки с помощью которого прроисходит экструзия определенного диаметра для послойного построения модели"],
    ["hotspot-11", "225 13 1482 1477 1423 0.050 0.282 0.668", "Турбина обдува детали", "Вентилятор направленный в зону построения модели. Необходим для охлаждения определенных типов пластика и элементов модели при печати"],
    ["hotspot-12", "239 1 88 90 91 0.578 0.247 0.175", "Мотор оси Y", "Мотор двигающий шкив и приводящий в движение Балку"],
    ["hotspot-13", "179 1 88 90 91 0.138 0.567 0.295", "Мотор оси X", "Мотор двигающий шкив и приводящий в движение Балку"],
]

const AnnotationsMask = ({infoVisible}) => {
    return (<>
        {infoVisible && 
            annotations.map(annotation => <Box
                key={annotation[0]}
                slot={annotation[0]} 
                data-surface={annotation[1]}
            >
                <Tooltip
                    arrow
                    title={
                        <Typography
                            sx={{ m: 1}}
                        >
                            {annotation[3]}
                        </Typography>
                    } 
                >
                    <Typography 
                        sx={{maxWidth: 200, fontWeight: 600, borderRadius: 1.5, bgcolor: "white", p: 0.5, fontSize: 14}}
                    >
                        {annotation[2]}
                    </Typography>
                </Tooltip>
            </Box>)
        }
    </>)
} 

export {AnnotationsMask}