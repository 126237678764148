import { createTheme } from '@mui/material';

const mainTheme = createTheme({
    palette: {
        primary: {
            main: "#0d436e",
            dark: "#0561a5",
        },
    }
});

export {mainTheme};